<template>
	<div class="grant">
		<en-table-layout
			:tableData="marketList"
			@selection-change="handleSelectionChange2">
			<div slot="toolbar" class="inner-toolbar">
				<div class="toolbar-btns">
					<div class="conditions">
						<span>卡券名称：</span>
						<el-input size="medium" placeholder="请输入卡券名称" v-model="params.c1c1"></el-input>
					</div>
					<div class="conditions">
						<span>销售公司名称：</span>
						<el-input size="medium" placeholder="请输入销售公司名称" v-model="params.d1d1"></el-input>
					</div>
					<div class="conditions">
						<span>卡券创建时间：</span>
						<el-date-picker
						size="medium"
							v-model="params.e1e1"
							type="date"
							placeholder="请选择卡券创建时间">
						</el-date-picker>
					</div>
					<div class="conditions">
						<el-button type="primary" size="small" @click="nuxt('steps1Info')">搜索</el-button>
					</div>
				</div>
			</div>
			<template slot="table-columns">
				<el-table-column label="卡券名称">
					<template slot-scope="scope">{{ scope.row.aaaa }}</template>
				</el-table-column>
				<el-table-column label="所属销售公司">
					<template slot-scope="scope">{{ scope.row.bbbb }}</template>
				</el-table-column>
				<el-table-column label="卡券创建时间" width="160">
					<template slot-scope="scope">{{ scope.row.cccc | unixToDate('yyyy-MM-dd hh:mm') }}</template>
				</el-table-column>
				<el-table-column label="卡券总数">
					<template slot-scope="scope">{{ scope.row.dddd }}</template>
				</el-table-column>
				<el-table-column label="已使用数">
					<template slot-scope="scope">￥{{ scope.row.eeee }}</template>
				</el-table-column>
				<el-table-column label="已过期数">
					<template slot-scope="scope">￥{{ scope.row.ffff }}</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button
							size="mini"
							type="primary"
							@click="tagShowFunc(scope.row)">查看
						</el-button>
					</template>
				</el-table-column>
			</template>
			<el-pagination
				slot="pagination"
				v-if="pageData2"
				@size-change="handlePageSizeChange2"
				@current-change="handlePageCurrentChange2"
				:current-page="pageData2.page_no"
				:page-size="pageData2.page_size"
				:page-sizes="MixinPageSizes"
				:layout="MixinTableLayout"
				background
			:total="pageData2.data_total">
			</el-pagination>
		</en-table-layout>

		<!--添加标签类型-->
		<el-dialog title="添加标签类型" :visible.sync="tagShow" width="600px">
			<div class="tagForm">
				<el-form
					:model="tagInfo"
					ref="tagInfo"
					:rules="tagInfoRules"
					label-width="180px"
				>
					<el-form-item label="标签名称：" prop="a4a4">
						<el-input style="width: 214px;" placeholder="请输入标签名称" v-model="tagInfo.a4a4"></el-input>
					</el-form-item>
					<el-form-item class="w200" label="自动打标签条件：">
						<span class="sTitle">累计成功交易</span>
						<el-input-number style="width: 100px;" :controls="false" v-model="tagInfo.b4b4" :min="0"></el-input-number> 笔
					</el-form-item>
					<el-form-item class="w200" label="或者：" prop="b4b4">
						<span class="sTitle">累计购买金额</span>
						<el-input-number style="width: 100px;" :controls="false" v-model="tagInfo.c4c4" :min="0"></el-input-number> 元
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer">
				<el-button type="primary" size="small" @click="tagShow = false">取消</el-button>
				<el-button type="primary" size="small" @click="tagShow = false">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// import * as API_HotGoods from '@/api/hotGoods'
	import vueQr from 'vue-qr'
	// import { handleDownload } from '@/utils'
	// import Sortable from 'sortablejs'
	import { UE } from '@/components'
  import EnTableLayout from '../../../ui-components/TableLayout/src/main';
	export default {
		name: 'hotSellingGoods',
		components: {
      EnTableLayout,
			[UE.name]: UE,
			vueQr
		},
		data() {
			return {
				downLoadType: 0,
				downLoadType1: 0,
				type: 1,
				// 2
				tableData: {},
				pageData: {},
				params: {},
				marketShow: false,
				marketInfo: {
					a5a5: 1,
				},
				marketList: [
					{
						aaaa: '春节福利',
						bbbb: '京东',
						cccc: new Date(),
						dddd: 300,
						eeee: 200,
						ffff: 20,
					}
				],
				marketGoods: [],
				pageData1: {},
				pageData2: {},

				tagShow: false,
				tagInfo: {},
				tagInfoRules: {
					a4a4: [
						{ required: true, message: '请输入标签名称', trigger: 'blur' }
					],
				},
			}
		},
		mounted() {

		},
		methods: {
			tagShowFunc(info){
				this.$router.push({ name: 'grantDetail' })
			},
			/** 保存店铺设置*/
			nuxt(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {

						// API_Shop.saveShopSettings(_params).then(() => {
						// 	this.$message.success('保存店铺设置成功')
						// 	this.GET_ShopGradeData()
						// })

						this.steps = 1
					}
				})
			},
			handleSelectionChange(val){
				let ids = []
				val.forEach(item => {
					ids.push(item.goods_id)
				})
				this.idsList = ids
			},
			handleSelectionChange1(val){
				this.marketGoods = val
			},
			handleSelectionChange2(val){
				this.marketGoods = val
			},
			/** 分页大小发生改变 */
			handlePageSizeChange(size) {
				this.params.page_size = size
				this.GET_GoodsList()
			},

			/** 分页页数发生改变 */
			handlePageCurrentChange(page) {
				this.params.page_no = page
				this.GET_GoodsList()
			},
			/** 分页大小发生改变 */
			handlePageSizeChange1(size) {
				this.params.page_size = size
				this.GET_GoodsList()
			},

			/** 分页页数发生改变 */
			handlePageCurrentChange1(page) {
				this.params.page_no = page
				this.GET_GoodsList()
			},
			/** 分页大小发生改变 */
			handlePageSizeChange2(size) {
				this.params.page_size = size
				this.GET_GoodsList()
			},

			/** 分页页数发生改变 */
			handlePageCurrentChange2(page) {
				this.params.page_no = page
				this.GET_GoodsList()
			},
			/** 查看商品 */
			seeMeal(row) {
				this.kalmanShow = true
				this.seeMealInfo = row
			},
			/** 删除商品 */
			handleDeleteMeal(row) {
				this.$confirm('确认删除此礼包, 是否继续?', '提示', { type: 'warning' }).then(() => {
					const _ids = [row.goods_id].toString()
					API_goods.deleteGoods(_ids).then(() => {
						this.GET_GoodsList()
						this.$message.success('删除礼包成功！')
					})
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.grant{background: #fff;padding:0 20px 20px; border-radius: 5px;}
	.stepsBox{margin-top: 20px;}
	.dialog-footer{float: right;margin-top: 20px;}
	.tipBox{width: 440px;background: rgba(242, 242, 242, 1);border-radius: 6px;padding: 10px 20px;margin-bottom: 20px;}
	.tipBox p{line-height: 30px;font-size: 14px;color: #666;margin: 0;}
	.tipBox.b1 p{line-height: 24px;font-size: 12px;}
	.tipBox.b2{width: 550px;}
	.tipBox.b2 p{line-height: 24px;font-size: 12px;}
	.shep{margin-bottom: 15px;}

	.titleTop{margin-bottom: 10px;}
	.titleTop .right{float: right;}
	.sTitle{margin-right: 10px;}

	.inner-toolbar >>> .el-input{width: 180px;}
</style>


<style lang="scss" scoped>
	.setMeal{background: #fff;padding: 20px;}
	.stepsForm{width: 500px;padding: 40px 0 30px; border-radius: 10px;border: 1px solid #e6ebf5;margin:40px auto;}
	.steps0 >>> .el-input--small .el-input__inner{width: 220px;}
	.btnAll{text-align: center;padding-bottom: 20px;}
	.grant >>> .inner-toolbar{padding: 20px 0;}
	.conditions{font-size: 14px;display: inline-block;margin-right: 20px;}
	.grant >>> .toolbar{height: auto;}
	.conditions1{display: block;margin-top: 10px;}
	.dialog-footer{text-align: center;}
	.addMealForm{width: 400px;margin: auto;}
	.addMealForm >>> .el-input--small .el-input__inner{width: 216px;}
	.w200 >>> .el-input--small .el-input__inner{width: 200px;}
	.addMealGoods{width: 510px;margin: auto;}
	.addMealGoods .box{display: inline-block;width: 150px;border:1px solid #e6ebf5;position: relative;margin: 10px;}
	.addMealGoods .box img{display: block;width: 100%;height: 136px;}
	.addMealGoods .box p{background: #f9f9f9;line-height: 40px;text-align: center;padding: 0 6px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;margin: 0;border-top: 1px solid #e6ebf5;}
	.addMealGoods .box i{position: absolute;right: 5px;top: 5px;cursor: pointer;}
	.addBtn{margin-left: 135px;margin-top: 30px;margin-bottom: 10px;}

	.titleTop{margin-bottom: 10px;}
	.titleTop .right{float: right;}

	.btnAll2{text-align: center;margin-bottom: 40px;}
	.boxAuto{width: 313px;height: 504px;overflow: auto;background: #fff;position: relative;top: 42px;left: 19px;}

	.warp{position: relative;}
	.warp .btn{position: absolute;right: 10px;top: 10px;}
	.warp img{display: block;width: 100%;min-height: 200px}
	.goodsList{text-align: center;padding-bottom: 30px;}
	.goodsList h5{padding: 0 10px;font-weight: 500;font-size:14px;text-align: left;margin-top: 20px;margin-bottom: 10px;}
	.goodsList h5 span{float: right;}
	.goodsList .box{display: inline-block;width: 44%;margin: 2%;border:1px solid #eee;border-radius:5px;}
	.goodsList .box img{display: block;width: 100%;height: 100px;}

</style>
<style lang="scss" scoped>
	.goods-images {
		/deep/ li.el-upload-list__item:first-child {
			position: relative;
		}
		/deep/ li.el-upload-list__item:first-child:after {
			content:"封";
			color:#fff;
			font-weight:bold;
			font-size: 12px;
			position: absolute;
			left: -15px;
			top: -6px;
			width: 40px;
			height: 24px;
			padding-top: 6px;
			background: #13ce66;
			text-align: center;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
			-webkit-box-shadow: 0 0 1pc 1px rgba(0,0,0,.2);
			box-shadow: 0 0 1pc 1px rgba(0,0,0,.2);
		}
	}
</style>
